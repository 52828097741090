import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Emissivity = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { emissivity } = useTranslations();

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{emissivity}</h2>
					<div className="content-container doc">
						<div className="flex column">
							<div className="header medium lh">
								測定物の放射率は同一名称であっても、その組成、表面処理、きめのこまかさ、色そして測定時の温度等で影響を受けます。
								<br />
								ある物体の放射率を表示することは非常に困難です。特に金属は表面の加工で放射率は大きく変わります。そのため表はあくまでも参考としてお考えください。
								<br />
								放射温度計のご利用に際し実際の測定物の温度を精度の高い接触式表面温度計等で測定し、機器の放射率を調整してご使用ください。
							</div>
							<div className="content">
								<table className="table">
									<thead>
										<tr>
											<th className="th medium">物体</th>
											<th className="th medium">放射率（ε）</th>
										</tr>
									</thead>
									<tbody>
　　									<tr>
											<td className="medium td">鉄</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">鉄(亜鉛メッキ）</td>
											<td className="medium td">0.28</td>
										</tr>
										<tr>
											<td className="medium td">鉄（ひどく錆びている）</td>
											<td className="medium td">0.91~0.96</td>
										</tr>
										<tr>
											<td className="medium td">ニッケル</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">アルミニューム</td>
											<td className="medium td">0.30</td>
										</tr>
										<tr>
											<td className="medium td">
												アルミニューム（表面アルマイト処理）
											</td>
											<td className="medium td">0.77</td>
										</tr>
										<tr>
											<td className="medium td">
												アルミニューム（表面磨きあげ）
											</td>
											<td className="medium td">0.05</td>
										</tr>
										<tr>
											<td className="medium td">銅</td>
											<td className="medium td">0.80</td>
										</tr>
										<tr>
											<td className="medium td">銅（表面磨きあげ）</td>
											<td className="medium td">0.03</td>
										</tr>
										<tr>
											<td className="medium td">真鍮</td>
											<td className="medium td">0.60～0.65</td>
										</tr>
										<tr>
											<td className="medium td">真鍮（表面磨きあげ）</td>
											<td className="medium td">0.05</td>
										</tr>
										<tr>
											<td className="medium td">ニクロム</td>
											<td className="medium td">0.60</td>
										</tr>
										<tr>
											<td className="medium td">ガラス</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">ファイバーグラス</td>
											<td className="medium td">0.75</td>
										</tr>
										<tr>
											<td className="medium td">セラミック</td>
											<td className="medium td">0.80</td>
										</tr>
										<tr>
											<td className="medium td">タイル</td>
											<td className="medium td">0.80</td>
										</tr>
										<tr>
											<td className="medium td">アスベスト</td>
											<td className="medium td">0.90</td>
										</tr>
										<tr>
											<td className="medium td">アスファルト</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">コンクリート</td>
											<td className="medium td">0.92～0.95</td>
										</tr>
										<tr>
											<td className="medium td">モルタル</td>
											<td className="medium td">0.87</td>
										</tr>
										<tr>
											<td className="medium td">モルタル（乾燥状態）</td>
											<td className="medium td">0.94</td>
										</tr>
										<tr>
											<td className="medium td">石膏ボード</td>
											<td className="medium td">0.90</td>
										</tr>
										<tr>
											<td className="medium td">土</td>
											<td className="medium td">0.92～0.95</td>
										</tr>
										<tr>
											<td className="medium td">砂</td>
											<td className="medium td">0.90</td>
										</tr>
										<tr>
											<td className="medium td">耐火レンガ</td>
											<td className="medium td">0.68</td>
										</tr>
										<tr>
											<td className="medium td">木材</td>
											<td className="medium td">0.86～0.98</td>
										</tr>
										<tr>
											<td className="medium td">紙</td>
											<td className="medium td">0.92</td>
										</tr>
										<tr>
											<td className="medium td">紙（黒、光沢）</td>
											<td className="medium td">0.90</td>
										</tr>
										<tr>
											<td className="medium td">紙（白）</td>
											<td className="medium td">0.68</td>
										</tr>
										<tr>
											<td className="medium td">紙（ダンボール）</td>
											<td className="medium td">0.81</td>
										</tr>
										<tr>
											<td className="medium td">
												壁紙（模様つき、ライトグレー）
											</td>
											<td className="medium td">0.85</td>
										</tr>
										<tr>
											<td className="medium td">布</td>
											<td className="medium td">0.75</td>
										</tr>
										<tr>
											<td className="medium td">プラスチック</td>
											<td className="medium td">0.91～0.95</td>
										</tr>
										<tr>
											<td className="medium td">ゴム</td>
											<td className="medium td">0.95～0.97</td>
										</tr>
										<tr>
											<td className="medium td">カーボン</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">人間の皮膚</td>
											<td className="medium td">0.97</td>
										</tr>
										<tr>
											<td className="medium td">水</td>
											<td className="medium td">0.95</td>
										</tr>
										<tr>
											<td className="medium td">海水</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">雪</td>
											<td className="medium td">0.80~0.85</td>
										</tr>
										<tr>
											<td className="medium td">氷</td>
											<td className="medium td">0.97</td>
										</tr>
										<tr>
											<td className="medium td">肉・魚</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">野菜</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">パン・菓子</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">穀類</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">油</td>
											<td className="medium td">0.98</td>
										</tr>
										<tr>
											<td className="medium td">塗料</td>
											<td className="medium td">0.98</td>
										</tr>
									</tbody>
								</table>
								<p className="medium">
									＊一覧表のデータは主な物体の放射率の大まかな目安です
								</p>
							</div>
						</div>
					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Emissivity;
